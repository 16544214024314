const teachers = [
  {
    id: 1,
    class: "10",
    division: "B",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "B-VE",
    dateOfBirth: "2002-05-28",
    staffId: 134,
    region: "Assam",
    district: "Karimganj",
    circuit: "Adarkona",
    address: "location somewhere",
    status: false,
  },
  {
    id: 2,
    class: "2",
    division: "B",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "B+VE",
    dateOfBirth: "2002-05-28",
    staffId: 145,
    region: "Andhra Pradesh",
    district: "Ananthapur",
    circuit: "A Narayanapuram",
    address: "location somewhere",
    status: true,
  },
  {
    id: 3,
    class: "1",
    division: "A",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "A+VE",
    dateOfBirth: "2002-05-28",
    staffId: 145,
    region: "Andhra Pradesh",
    district: "Ananthapur",
    circuit: "A Narayanapuram",
    address: "location somewhere",
    status: true,
  },
  {
    id: 4,
    class: "11",
    division: "A",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "A+VE",
    dateOfBirth: "2002-05-28",
    staffId: 141,
    region: "Andhra Pradesh",
    district: "Ananthapur",
    circuit: "A Narayanapuram",
    address: "location somewhere",
    status: false,
  },
  {
    id: 5,
    class: "10",
    division: "C",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "O+VE",
    dateOfBirth: "2002-05-28",
    staffId: 155,
    region: "Andhra Pradesh",
    district: "Ananthapur",
    circuit: "A Narayanapuram",
    address: "location somewhere",
    status: true,
  },
  {
    id: 6,
    class: "6",
    division: "C",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "O-VE",
    dateOfBirth: "2002-05-28",
    staffId: 235,
    region: "Andhra Pradesh",
    district: "Ananthapur",
    circuit: "A Narayanapuram",
    address: "location somewhere",
    status: false,
  },
  {
    id: 7,
    class: "6",
    division: "A",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "AB+VE",
    dateOfBirth: "2002-05-28",
    staffId: 145,
    region: "Gujarat",
    district: "Surat",
    circuit: "Adajan",
    address: "location somewhere",
    status: true,
  },
  {
    id: 8,
    class: "4",
    division: "A",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "AB-VE",
    dateOfBirth: "2002-05-28",
    staffId: 115,
    region: "Gujarat",
    district: "Surat",
    circuit: "Varachha",
    address: "location somewhere",
    status: false,
  },
  {
    id: 9,
    class: "12",
    division: "D",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    staffId: 101,
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "O-VE",
    dateOfBirth: "2002-05-28",
    staffId: 178,
    staffId: 145,
    region: "Gujarat",
    district: "Surat",
    circuit: "Adajan",
    address: "location somewhere",
    status: true,
  },
  {
    id: 10,
    class: "2",
    division: "F",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "O-VE",
    dateOfBirth: "2002-05-28",
    staffId: 143,
    staffId: 145,
    region: "Gujarat",
    district: "Surat",
    circuit: "Varachha",
    address: "location somewhere",
    status: true,
  },
  {
    id: 11,
    class: "5",
    division: "F",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "O-VE",
    dateOfBirth: "2002-05-28",
    staffId: 143,
    region: "Gujarat",
    district: "Surat",
    circuit: "Varachha",
    address: "location somewhere",
    status: true,
  },
  {
    id: 12,
    class: "10",
    division: "B",
    fullName: "Neal Matthews",
    education: "B.ED",
    phoneNumber: "9879879898",
    altPhoneNumber: "9879879898",
    email: "demo@gmail.com",
    profilePic: "profile_pic.png",
    degreeCertificate: "degree-certi_pic.png",
    bloodGroup: "B+ve",
    dateOfBirth: "2002-05-28",
    staffId: 133,
    region: "Assam",
    district: "Karimganj",
    circuit: "Adarkona",
    address: "location somewhere",
    status: true,
  },
]

export { teachers }
