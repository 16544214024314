const classes = [
  {
    id: 1,
    class: 1,
    division: "A",
    noOfStudents: 48,
    classTeacher: "Monika Jonhn",
    status: true,
  },
  {
    id: 2,
    class: 2,
    division: "B",
    noOfStudents: 52,
    classTeacher: "Monika Jonhn",
    status: true,
  },
  {
    id: 3,
    class: 3,
    division: "A",
    noOfStudents: 42,
    classTeacher: "Monika Jonhn",
    status: true,
  },
  {
    id: 4,
    class: 4,
    division: "A",
    noOfStudents: 58,
    classTeacher: "Monika Jonhn",
    status: true,
  },
  {
    id: 5,
    class: 5,
    division: "C",
    noOfStudents: 35,
    classTeacher: "Monika Jonhn",
    status: true,
  },
  {
    id: 6,
    class: 7,
    division: "A",
    noOfStudents: 40,
    classTeacher: "Jonny Bairstow",
    status: false,
  },
  {
    id: 7,
    class: 7,
    division: "B",
    noOfStudents: 74,
    classTeacher: "Monika Jonhn",
    status: true,
  },
  {
    id: 8,
    class: 8,
    division: "C",
    noOfStudents: 49,
    classTeacher: "Jonny Bairstow",
    status: true,
  },
  {
    id: 9,
    class: 10,
    division: "B",
    noOfStudents: 35,
    classTeacher: "Monika Jonhn",
    status: true,
  },
  {
    id: 10,
    class: 9,
    division: "B",
    noOfStudents: 42,
    classTeacher: "Marting Gupltil",
    status: false,
  },
  {
    id: 11,
    class: 10,
    division: "A",
    noOfStudents: 29,
    classTeacher: "Monika Jonhn",
    status: true,
  },
  {
    id: 12,
    class: 12,
    division: "D",
    noOfStudents: 24,
    classTeacher: "Marting Gupltil",
    status: false,
  },
  {
    id: 13,
    class: 6,
    division: "C",
    noOfStudents: 57,
    classTeacher: "Ben Stokes",
    status: true,
  },
  {
    id: 14,
    class: 11,
    division: "A",
    noOfStudents: 35,
    classTeacher: "Andrew Philip",
    status: true,
  },
  {
    id: 15,
    class: 12,
    division: "B",
    noOfStudents: 18,
    classTeacher: "Ben Stokes",
    status: false,
  },
  {
    id: 16,
    class: 10,
    division: "C",
    noOfStudents: 20,
    classTeacher: "Andrew Philip",
    status: true,
  },
]

export { classes }
